import { countriesOptions } from '@/utils/countries'
import config from '@/config/global'

function movedLessThan3YearsAgo(value) {
  if (value && value.length === 10) {
    const dateMoved = new Date(value).setHours(0, 0, 0, 0)
    const today = new Date()
    const threeYearsAgo = new Date().setMonth(today.getMonth() - 36)
    return dateMoved > threeYearsAgo
  }
  return false
}

export default [
  {
    name: 'business',
    title: 'Business Information',
    groups: [
      {
        title: 'Business Information',
        fields: [
          [
            {
              field: 'input',
              type: 'text',
              name: 'companyName',
              label: 'Business Name',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'input',
              type: 'number',
              name: 'companyNumber',
              label: 'Company Registration Number',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'input',
              type: 'text',
              name: 'registeredAddressLine',
              label: 'Registered Office Address',
              rules: 'required',
              selected: ''
            },
            {
              field: 'input',
              type: 'text',
              name: 'registeredAddressCity',
              label: 'City/Town',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'input',
              type: 'text',
              name: 'registeredAddressPostcode',
              label: 'Postcode',
              rules: 'required',
              selected: ''
            },
            {
              choice: 'single',
              field: 'select',
              name: 'registeredAddressCountry',
              veeAs: 'registered address country',
              label: 'Country',
              rules: 'required',
              options: countriesOptions,
              selected: ''
            }
          ],
          [
            {
              field: 'input',
              type: 'checkbox',
              isInline: true,
              isCountable: false,
              name: 'hasDifferentTradingAddress',
              label: 'Physical Business has a different address.',
              rules: '',
              selected: false
            }
          ],
          [
            {
              field: 'input',
              type: 'text',
              name: 'tradingAddressLine',
              label: 'Physical Business Address',
              rules: 'required',
              selected: '',
              dependsOn: [
                {
                  fieldName: 'hasDifferentTradingAddress',
                  values: [true]
                }
              ]
            },
            {
              field: 'input',
              type: 'text',
              name: 'tradingAddressCity',
              label: 'City/Town',
              rules: 'required',
              selected: '',
              dependsOn: [
                {
                  fieldName: 'hasDifferentTradingAddress',
                  values: [true]
                }
              ]
            }
          ],
          [
            {
              field: 'input',
              type: 'text',
              name: 'tradingAddressPostCode',
              label: 'Postcode',
              rules: 'required',
              selected: '',
              dependsOn: [
                {
                  fieldName: 'hasDifferentTradingAddress',
                  values: [true]
                }
              ]
            },
            {
              choice: 'single',
              field: 'select',
              name: 'tradingAddressCountry',
              veeAs: 'registered address country',
              label: 'Country',
              rules: 'required',
              options: countriesOptions,
              selected: '',
              dependsOn: [
                {
                  fieldName: 'hasDifferentTradingAddress',
                  values: [true]
                }
              ]
            }
          ],
          [
            {
              field: 'input',
              type: 'number',
              name: 'companyPhoneNumber',
              label: 'Business Phone Number',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'input',
              type: 'text',
              name: 'companyEmail',
              label: 'Business Email',
              rules: 'required|email',
              selected: ''
            }
          ],
          [
            {
              field: 'input',
              type: 'numerical',
              name: 'tradingAgeRanges',
              label: 'Years trading',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'input',
              type: 'numerical',
              name: 'yearsOfCurrentOwnership',
              label: 'Years of current ownership (Current Director)',
              rules: 'required',
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'financial',
    title: 'Financial Information',
    groups: [
      {
        title: '',
        fields: [
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'commentary',
              label:
                'Commentary on recent and historic performance of the business. Include detail and explanation of any add-backs. You must be able to demonstrate that the business was viable prior to Covid-19:',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'holdSecurity',
              label: 'Does your current bank hold security? if so, what do they hold and what headroom is available?',
              rules: '',
              selected: ''
            }
          ],
          [
            {
              choice: 'single',
              field: 'input',
              type: 'number',
              name: 'creditDebitCardMonthlyAverage',
              label: 'Average Monthly Sales via Card Terminal',
              placeholder: '',
              rules: 'required|numeric',
              selected: ''
            }
          ],
          [
            {
              choice: 'single',
              field: 'input',
              type: 'number',
              name: 'avgMonthlyOnlineSales',
              label: 'Average Monthly Online Sales',
              placeholder: '',
              rules: 'required|numeric',
              selected: ''
            }
          ],
          [
            {
              choice: 'single',
              field: 'input',
              type: 'number',
              name: 'overdraftLimit',
              label: 'Overdraft Limit',
              placeholder: '',
              rules: '',
              selected: ''
            }
          ],
          [
            {
              choice: 'single',
              field: 'select',
              name: 'currentlyBank',
              label: 'Where do you currently bank?',
              placeholder: '',
              rules: 'required',
              options: config.whitelabel.forms.currentlyBankOptions,
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'awareness',
              label: 'Are you aware of any adverse personal or business events which would affect this application for credit?',
              rules: 'required',
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'covid',
    title: 'Covid-19 Impact Statement',
    groups: [
      {
        title: 'Covid-19 Impact Statement',
        fields: [
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'impact',
              label: 'What is the impact of COVID-19 now?',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'changes',
              label: 'What changes is the business making in the short to medium term to help drive business performance either back to pre-virus levels or beyond?',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'otherOptions',
              label:
                'What other options have been explored e.g. Furlough, rate relief, time to pay arrangements, local authority or other grant or loan schemes applied for during Covid-19?',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'purpose',
              label: 'What is the purpose of the facility (short term cash flow/longer term cash injection) and how will the funds be specifically used?',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'payingSuppliers',
              label: 'If Paying suppliers, evidence of amounts owed and who requires paying?',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'howWillFundsBeUsedCovid',
              label: 'How will the funds be used to address the cash shortfall caused by Covid-19?',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              type: 'text',
              name: 'afterPandemic',
              label:
                'Once the pandemic is over, what does the road to recovery look like for your business? (What challenges will you face, seasonality, loss of contracts, staff availability etc.)',
              rules: 'required',
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'loan',
    title: 'Loan Request Details',
    groups: [
      {
        title: 'Loan Request Details',
        fields: [
          [
            {
              field: 'input',
              type: 'number',
              name: 'totalFundingRequired',
              label: 'Amount',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              choice: 'single',
              field: 'select',
              name: 'fundingRepaymentPeriod',
              label: 'Term',
              placeholder: 'Click to choose',
              rules: 'required|numeric',
              options: [
                {
                  label: '1-2 months',
                  value: 1
                },
                {
                  label: '2-3 months',
                  value: 2
                },
                {
                  label: '3-6 months',
                  value: 3
                },
                {
                  label: '6 months - 1 year',
                  value: 6
                },
                {
                  label: '1 year - 2 years',
                  value: 13
                },
                {
                  label: '2 years - 5 years',
                  value: 25
                },
                {
                  label: '5+ years',
                  value: 61
                }
              ],
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              name: 'amountBorrowingDetails',
              label:
                'Provide detail on the amount of the borrowing request and how this amount has been derived. Expectation is that this amount is to cover essential expenditure and critical payments, to get through the interruption caused by Covid-19.',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              name: 'calculationBasis',
              label:
                'On what basis has the amount been calculated? Has this been based on existing (pre virus) income and expenditure levels or future levels? If future, what assumptions are being used?',
              rules: 'required',
              selected: ''
            }
          ],
          [
            {
              field: 'textarea',
              name: 'amountCoverNeeds',
              label: 'Will the amount requested cover the businesses actual needs, or is it likely you would need to request further borrowing later?',
              rules: 'required',
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'director',
    title: 'Director Information',
    group: {
      name: 'director',
      choice: 'multiple',
      fields: [
        [
          {
            name: 'companyDirectorDeleteBtn',
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            name: 'title',
            label: 'Title',
            placeholder: 'Not specified',
            rules: 'required',
            options: [
              { label: 'Mr', value: 'Mr' },
              { label: 'Mrs', value: 'Mrs' },
              { label: 'Ms', value: 'Ms' },
              { label: 'Miss', value: 'Miss' },
              { label: 'Dr', value: 'Dr' },
              { label: 'Lady', value: 'Lady' },
              { label: 'Lord', value: 'Lord' },
              { label: 'Sir', value: 'Sir' }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'fullName',
            label: 'Full Name',
            rules: 'required|name',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'dateOfBirth',
            label: 'Date of Birth (DD/MM/YYYY)',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'numerical',
            name: 'ownership',
            label: '% Ownership',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'buildingNumber',
            label: 'Building Number',
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'street',
            label: 'Street',
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'city',
            label: 'City',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'postcode',
            label: 'Postcode',
            rules: 'required',
            selected: ''
          },
          {
            choice: 'single',
            field: 'select',
            name: 'country',
            label: 'Country',
            rules: 'required',
            options: countriesOptions,
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'dateMoved',
            label: 'Date moved to this Address (DD/MM/YYYY)',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'previousBuildingNumber',
            label: 'Previous Building Number',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'dateMoved',
                rule: movedLessThan3YearsAgo
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'previousStreet',
            label: 'Previous Street',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'dateMoved',
                rule: movedLessThan3YearsAgo
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'previousCity',
            label: 'Previous City',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'dateMoved',
                rule: movedLessThan3YearsAgo
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'previousPostcode',
            label: 'Previous Postcode',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'dateMoved',
                rule: movedLessThan3YearsAgo
              }
            ],
            selected: ''
          },
          {
            choice: 'single',
            field: 'select',
            name: 'previousCountry',
            label: 'Previous Country',
            rules: 'required',
            options: countriesOptions,
            dependsOn: [
              {
                fieldName: 'dateMoved',
                rule: movedLessThan3YearsAgo
              }
            ],
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'number',
            name: 'mobileNumber',
            label: 'Mobile Number',
            rules: 'required',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'email',
            label: 'Email',
            rules: 'required|email',
            selected: ''
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            name: 'residentialStatus',
            label: 'Residential status',
            placeholder: 'Select a status',
            rules: 'required',
            options: [
              {
                label: 'Not Found',
                value: 'NotFound'
              },
              {
                label: 'Home Owner',
                value: 'HomeOwner'
              },
              {
                label: 'Living With Parents',
                value: 'LivingWithParents'
              },
              {
                label: 'Tenant',
                value: 'Tenant'
              },
              {
                label: 'Not Provided',
                value: 'NotProvided'
              },
              {
                label: 'Other',
                value: 'Other'
              },
              {
                label: 'Private Tenant',
                value: 'PrivateTenant'
              },
              {
                label: 'Council Tenant',
                value: 'CouncilTenant'
              },
              {
                label: 'Home Owner No Mortgage',
                value: 'HomeOwnerNoMortgage'
              }
            ],
            selected: ''
          }
        ]
      ]
    },
    groups: [
      {
        title: 'Director Information',
        fields: []
      }
    ]
  },
  {
    name: 'documents',
    title: 'Required Documents'
  }
]
