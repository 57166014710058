<template>
  <div class="ui stackable container grid application-form">
    <nav class="five wide column no-print">
      <BackBtn />
      <ul class="list-group">
        <li v-for="(step, stepIndex) in steps" :key="stepIndex">
          <router-link class="list-group-item-navigator" :to="{ params: { step: step.name } }" replace>
            <span class="text">{{ step.title }}</span>
            <template v-if="!isLoading">
              <span class="complete-icon" v-if="isCompletedStepsList[step.name]">
                <i class="material-icons">check</i>
              </span>
              <span class="progress" v-else>
                <template class="progress">{{ stepsProgress[step.name].completed }}/{{ stepsProgress[step.name].total }}</template>
              </span>
            </template>
          </router-link>
        </li>
      </ul>
    </nav>
    <section class="eleven wide column">
      <div class="row ui flex center">
        <header class="col-xs-12 col-md-12 col-lg-12 text-left form-title">
          <h3 class="ui h1 text f400">Swoop Application Form</h3>
        </header>
        <div class="col-xs-12 col-md-12 form-inner">
          <div class="align-form" v-if="isLoading">
            <div class="ui active inverted dimmer page-loader">
              <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
            </div>
          </div>
          <div v-else>
            <div v-if="currentStep.name === 'financial'" class="financial">
              <div class="ui flex column">
                <label class="ui text dark header f400">Financial Information</label>
                <table class="table table-v-align">
                  <thead>
                    <tr>
                      <th v-for="(header, index) in financialInfo.firstTable.columns" :key="index" class="heading">
                        {{ header.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in financialInfo.firstTable.data" :key="index">
                      <td
                        v-for="(column, index) in financialInfo.firstTable.columns"
                        :key="index"
                        class="value"
                        :contenteditable="column.key !== 'field'"
                        :data-column="column.key"
                        :data-field="row._field"
                        @blur="onChangeFinancialInfo"
                        @keypress="validateTableCell"
                      >
                        {{ row[column.key] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span v-if="financialInfo.firstTable.error" class="error-financialinfo text-danger ui pointing above red basic label"
                  >{{ turnoverLabel }}, EBITDA, Add-backs and Adjusted EBITDA are required fields</span
                >
                <table class="table table-v-align">
                  <thead>
                    <tr>
                      <th v-for="(header, index) in financialInfo.secondTable.columns" :key="index" class="heading">
                        {{ header.label }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, rowIndex) in financialInfo.secondTable.data" :key="rowIndex">
                      <td
                        v-for="(column, index) in financialInfo.secondTable.columns"
                        :key="index"
                        class="value"
                        contenteditable="true"
                        :data-column="column.key"
                        :data-row="rowIndex"
                        :data-text="column.text === true"
                        @keypress="validateTableCell"
                        @blur="onChangeTypeOfDebt"
                      >
                        {{ row[column.key] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button v-if="financialInfo.secondTable.data.length > 0" type="button" class="ui button remove-debt" @click.prevent="removeTypeOfDebt()">
                  Remove Last Type of Debt
                </button>
                <button type="button" class="ui button circular primary" @click.prevent="addTypeOfDebt()">Add Type of Debt</button>
              </div>
            </div>
            <div v-else-if="currentStep.name === 'documents'" class="documents">
              <div class="documents-header -plus col-xs-12 col-sm-12 col-md-12">
                <div class="ui flex column">
                  <label class="ui text dark header f400">Required documents</label>
                  <ProductRequiredDocuments class="profile" v-bind="{ documentsList, requiredDocumentsList }" @change="documentsListChangeHandler" />
                </div>
              </div>
            </div>
            <DynamicForm class="out-form align-form" v-model="currentStep" :submit-promise="submitPromise" @df-submit="submit" :submitText="submitText">
              <template #companyDirectorDeleteBtn="{ field, group, value }">
                <div class="director-header -plus col-xs-12 col-sm-12 col-md-12">
                  <div class="ui flex column">
                    <h2 class="title title-sm m-b-0 director-title">{{ value.groups.findIndex(g => g === group) + 1 }}. Director Information</h2>
                  </div>
                  <button
                    class="btn btn-secondary btn-director"
                    type="button"
                    @click="deleteCompanyDirector(value.groups, group)"
                    v-if="isCompanyDirectorDeleteBtnVisible(value.groups, group)"
                  >
                    Remove this director
                  </button>
                </div>
              </template>
              <template #addCompanyDirectorBtn="{ value }">
                <div class="director-header col-xs-12 col-sm-12 col-md-12 ui flex center m-t-2">
                  <button class="btn btn-secondary btn-add-company-director" type="button" @click="addCompanyDirector(value.groups)">Add another director</button>
                </div>
              </template>
              <template v-slot:afterSubmit>
                <button :disabled="!isCompletedStepsList[currentStep.name]" type="button" class="ui button circular back" @click.prevent="printTab()">Generate PDF</button>
              </template>
            </DynamicForm>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import deepcopy from 'deepcopy'
import { mapGetters } from 'vuex'
import BackBtn from '@/components/BackBtn'
import DynamicForm from '@/components/forms/DynamicForm'
import { companyGetters } from '@/store/modules/company/routines'
import { fundingGetters } from '@/store/routines'
import { getCompany, getCompanyOwners } from '@/api/company'
import { getFundingApplication } from '@/api/fundingApplication'
import { prefillFormData, getStepProgress, generateGroups, generateGroup } from '@/utils/formHelpers'
import { toastDefaultOptions } from '@/config/vue-toast'
import steps from './applicationForm.steps'
import ProductRequiredDocuments from '@/components/ProductRequiredDocuments'
import { getDocumentsList } from '@/api/document'
import { requiredDocumentsAdapter } from '@/utils/utils'

export default {
  name: 'ApplicationForm',
  components: {
    BackBtn,
    DynamicForm,
    ProductRequiredDocuments
  },
  data() {
    return {
      isLoading: true,
      submitPromise: null,
      documentsListFromServer: null,
      documentsListCheckbox: [],
      steps: deepcopy(steps),
      submitText: 'save',
      financialInfo: {
        firstTable: {
          columns: [
            { key: 'field', label: '' },
            { key: 'secondMostRecent', label: '2nd Most Recent Financial Year End' },
            { key: 'mostRecent', label: 'Most Recent Financial Year End' },
            { key: 'managementAccounts', label: 'Management Accounts' }
          ],
          data: [
            {
              _field: 'turnover',
              field: 'Turnover',
              secondMostRecent: 12.54,
              mostRecent: 12.54,
              managementAccounts: 1200
            },
            {
              _field: 'ebitda',
              field: 'EBITDA'
            },
            {
              _field: 'add-backs',
              field: 'Add-backs'
            },
            {
              _field: 'adjusted_ebitda',
              field: 'Adjusted EBITDA'
            }
          ],
          error: false
        },
        secondTable: {
          columns: [
            { key: 'type', label: 'Type of Debt', text: true },
            { key: 'amount', label: 'Amount' },
            { key: 'monthlyRepayment', label: 'Monthly Repayment' },
            { key: 'expiry', label: 'Expiry', text: true },
            { key: 'securityProvided', label: 'Security Provided', text: true },
            { key: 'marketValueSecurity', label: 'Market Value Security' }
          ],
          data: [{}]
        }
      },
      turnoverLabel: this.$dictionary.turnover.charAt(0).toUpperCase() + this.$dictionary.turnover.slice(1)
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      fundingApplicationId: fundingGetters.FUNDING_APP_ID
    }),
    stepsProgress() {
      return this.steps.reduce((curr, step) => {
        if (step.name === 'financial') {
          const formProgress = getStepProgress(step)
          const completed = this.financialInfo.firstTable.data.reduce((curr, row) => curr + !!row.secondMostRecent + !!row.mostRecent + !!row.managementAccounts, 0)
          return {
            ...curr,
            financial: {
              total: formProgress.total + 12,
              completed: formProgress.completed + completed
            }
          }
        } else if (step.name === 'documents') {
          return {
            ...curr,
            documents: {
              total: 5,
              completed: this.requiredDocumentsList.reduce((total, doc) => (total += Number(doc.isDocumentUploaded)), 0)
            }
          }
        } else {
          return { ...curr, [step.name]: getStepProgress(step) }
        }
      }, {})
    },
    currentStep() {
      return this.steps.find(({ name }) => name === this.$route.params.step)
    },
    documentsList() {
      if (!Array.isArray(this.documentsListFromServer)) return this.documentsListFromServer
      return [...this.documentsListFromServer, ...this.documentsListCheckbox]
    },
    requiredDocumentsList() {
      const docs = requiredDocumentsAdapter({
        documentsList: this.documentsList || [],
        requiredDocuments: {
          hasBankStatements: 3,
          proofOfIdentity: true,
          hasManagementAccounts: true,
          filedAccounts: 2,
          hasVATReturns: true
        },
        isPrivateLimitedCompany: false,
        opportunity: {
          bankStatements: 6
        }
      })

      docs.forEach(doc => {
        switch (doc.key) {
          case 'hasBankStatements':
            doc.label = '6 Months Company Bank Statements'
            break
          case 'proofOfIdentity':
            doc.label = 'Copy of All Directors ID'
            break
          case 'hasManagementAccounts':
            doc.label = 'Latest Management Accounts'
            break
          case 'filedAccounts':
            doc.label = 'Last Filed Accounts'
            break
          case 'hasVATReturns':
            doc.label = `Last 4 ${this.$dictionary.vat} Returns`
            break
        }
      })

      return docs
    },
    isCompletedStepsList(index) {
      return this.steps.reduce((curr, step) => ({ ...curr, [step.name]: this.stepsProgress[step.name].completed === this.stepsProgress[step.name].total }), {})
    }
  },
  methods: {
    printTab() {
      this.submitText = ' '
      this.$nextTick(() => {
        window.print()
        this.submitText = 'save'
      })
    },
    validateTableCell(ev) {
      const isPoint = (event.charCode || event.keyCode) === 46
      const isNumeric = ev.target.getAttribute('data-text') === 'false' || ev.target.getAttribute('data-text') === null
      if (isNaN(String.fromCharCode(ev.which)) && !isPoint && isNumeric) ev.preventDefault()
    },
    onChangeFinancialInfo(ev) {
      const field = ev.target.getAttribute('data-field')
      const column = ev.target.getAttribute('data-column')
      const rowIndex = this.financialInfo.firstTable.data.findIndex(row => row._field === field)
      let row = this.financialInfo.firstTable.data[rowIndex]
      row = {
        ...row,
        [column]: isNaN(parseFloat(ev.target.textContent)) ? '' : parseFloat(ev.target.textContent)
      }
      this.financialInfo.firstTable.data.splice(rowIndex, 1, row)
    },
    onChangeTypeOfDebt(ev) {
      const rowIndex = ev.target.getAttribute('data-row')
      const column = ev.target.getAttribute('data-column')
      let row = this.financialInfo.secondTable.data[rowIndex]
      row = {
        ...row,
        [column]: isNaN(parseFloat(ev.target.textContent)) ? ev.target.textContent : parseFloat(ev.target.textContent)
      }
      this.financialInfo.secondTable.data.splice(rowIndex, 1, row)
    },
    addTypeOfDebt() {
      this.financialInfo.secondTable.data.push({})
    },
    removeTypeOfDebt() {
      this.financialInfo.secondTable.data.pop()
    },
    unableToPrefill(stepName) {
      const message = `Couldn't prefill ${stepName} data`
      this.$toasted.show(message, { ...toastDefaultOptions, type: 'warning', icon: 'warning', duration: 5000 })
    },
    submit({ name, data }) {
      const submitStep = () => {
        switch (name) {
          case 'business':
            return this.submitBusiness(data)
          case 'covid':
            return this.submitCovid(data)
          case 'loan':
            return this.submitLoan(data)
          case 'director':
            return this.submitDirector(data)
          case 'financial':
            return this.submitFinancial(data)
          default:
            return Promise.resolve()
        }
      }
      this.submitPromise = submitStep()
    },
    getStep(stepName) {
      return this.steps.find(step => step.name === stepName)
    },
    prefillBusiness() {
      if (this.companyId) {
        return getCompany(this.companyId)
          .then(({ data }) => {
            const { tradingAgeRanges } = data
            prefillFormData(this.getStep('business'), { ...data, tradingAgeRanges: tradingAgeRanges / 12 })
          })
          .catch(() => this.unableToPrefill('business'))
      }
      this.unableToPrefill('business')
      return Promise.resolve({})
    },
    submitBusiness(data) {
      const { tradingAgeRanges } = data
      return new Promise(resolve => setTimeout(() => resolve({ ...data, tradingAgeRanges: tradingAgeRanges * 12 }), 1000)).then(() => {
        this.$router.replace({ params: { step: 'financial' } })
      })
    },
    prefillfinancial() {
      prefillFormData(this.getStep('financial'))
      return Promise.resolve({})
    },
    submitFinancial(data) {
      return new Promise((resolve, reject) => {
        if (!this.isCompletedStepsList.financial) {
          this.financialInfo.firstTable.error = true
          this.$nextTick(() => {
            const errorElement = this.$el.querySelector('.text-danger')
            errorElement.parentNode.parentNode.scrollIntoView({ block: 'start', behavior: 'smooth' })
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              response: {
                data: {
                  message: `${this.turnoverLabel}, EBITDA, Add-backs and Adjusted EBITDA are required fields`
                }
              }
            })
          })
        } else {
          this.financialInfo.firstTable.error = false
          setTimeout(() => resolve(data), 1000)
        }
      }).then(() => {
        this.$router.replace({ params: { step: 'covid' } })
      })
    },
    prefillCovid() {
      prefillFormData(this.getStep('covid'))
      return Promise.resolve({})
    },
    submitCovid(data) {
      return new Promise(resolve => setTimeout(() => resolve(data), 1000)).then(() => {
        this.$router.replace({ params: { step: 'loan' } })
      })
    },
    prefillLoan() {
      const unableToPrefillLoan = () => {
        this.$toasted.clear()
        const message = "Couldn't prefill loan data"
        this.$toasted.show(message, { ...toastDefaultOptions, type: 'warning', icon: 'warning', duration: 5000 })
      }
      if (this.fundingApplicationId) {
        return getFundingApplication(this.fundingApplicationId)
          .then(({ data }) => {
            const { totalFundingRequired, fundingRepaymentPeriod } = data
            prefillFormData(this.getStep('loan'), { totalFundingRequired, fundingRepaymentPeriod })
          })
          .catch(unableToPrefillLoan)
      }
      unableToPrefillLoan()
      return Promise.resolve({})
    },
    submitLoan(data) {
      return new Promise(resolve => setTimeout(() => resolve(data), 1000)).then(() => {
        this.$router.replace({ params: { step: 'director' } })
      })
    },
    prefillDirector() {
      const directorStep = this.getStep('director')
      directorStep.groups = [{ name: 'addCompanyDirectorBtn', fields: [] }]
      if (this.companyId) {
        return getCompanyOwners(this.companyId)
          .then(({ data }) => {
            let companyDirectors = data.length ? data : [{}]
            companyDirectors = companyDirectors.map(director => ({
              ...director,
              fullName: `${director.firstName} ${director.lastName}`,
              dateOfBirth: new Date(director.dateOfBirth).toLocaleDateString('en-GB')
            }))
            const companyDirectorGroups = generateGroups(companyDirectors, directorStep.group)
            directorStep.groups = [...companyDirectorGroups, { name: 'addCompanyDirectorBtn', fields: [] }]
          })
          .catch(() => {
            this.unableToPrefill('director')
          })
      }
      this.unableToPrefill('director')
      return Promise.resolve({})
    },
    addCompanyDirector(groups) {
      const directorStep = this.getStep('director')
      const start = groups.length - 1
      const deleteCount = 0
      const item = generateGroup({}, directorStep.group)
      groups.splice(start, deleteCount, item)
    },
    isCompanyDirectorDeleteBtnVisible(groups) {
      const directorStep = this.getStep('director')
      const count = groups.reduce((acc, g) => (g.name === directorStep.name ? acc + 1 : acc), 0)
      return count > 1
    },
    deleteCompanyDirector(groups, group) {
      const index = groups.findIndex(g => g === group)
      if (index === -1) return
      groups.splice(index, 1)
    },
    submitDirector(data) {
      return new Promise(resolve => setTimeout(() => resolve(data), 1000)).then(() => {
        this.$router.replace({ params: { step: 'documents' } })
      })
    },
    prefillDocuments() {
      return this.loadDocumentsList().catch(() => {
        this.unableToPrefill('documents')
      })
    },
    prefill() {
      return Promise.allSettled([this.prefillBusiness(), this.prefillCovid(), this.prefillLoan(), this.prefillDirector(), this.prefillDocuments()])
    },
    loadDocumentsList() {
      this.documentsListFromServer = []
      return getDocumentsList(this.companyId).then(res => {
        this.documentsListFromServer = res.data
      })
    },
    documentsListChangeHandler() {
      this.loadDocumentsList()
    }
  },
  created() {
    if (!this.$route.params.step) {
      this.$router.replace({ params: { step: steps[0].name } })
    }

    this.prefill().finally(() => {
      this.isLoading = false
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

@media print {
  @page {
    size: auto;
    margin: 0mm;
  }
}
.ui.application-form {
  @media print {
    [class*='eleven wide'].column {
      width: 90% !important;
      margin: 0 auto;
    }
    .action-container *,
    .no-print,
    .no-print * {
      display: none !important;
    }
  }
  .btn-back {
    margin: 2rem 0 1rem;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .form-title {
    margin: 1rem 0;
    font-size: 20px;
    padding: 2rem !important;
    margin-bottom: 0rem;
    @media only screen and (max-width: 770px) {
      margin: 0rem;
    }
    .h1 {
      font-size: 1.75rem;
    }
  }
  .form-inner {
    .align-form {
      margin: 2rem;
      @media only screen and (max-width: 770px) {
        margin: 1rem;
      }

      .fields {
        @media only screen and (max-width: 770px) {
          margin: 1rem 0 !important;
        }
      }
    }
  }
  .director-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-primary-100);
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: var(--color-primary-500);
    .btn-director {
      display: inline-block;
      background-color: $color-white;
      font-size: 10px;
      line-height: 12px;
      color: $color-main;
      border: none;
      border-radius: 0;
      &:hover,
      &:focus {
        color: $color-main-hover;
      }
      &:active,
      &.is-active,
      &.active {
        color: $color-main-hover;
      }
    }

    .director-title {
      display: inline-block;
      font-weight: normal;
      font-size: 20px;
    }
  }
  textarea {
    min-height: 104px;
  }
  .btn-add-company-director {
    margin: 0 auto;
  }
  .documents-header {
    margin: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-primary-100);
    /deep/ {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          margin-bottom: 4px;
          border: 1px solid var(--color-primary-100);
          border-radius: 12px;
          a.link,
          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            text-decoration: none;
            > i {
              flex-shrink: 0;
              margin-left: 10px;
            }
          }
        }
      }
      .file-names {
        display: block;
        > li {
          display: inline-block;
          font-size: 0.75em;
          line-height: 1em;
          margin-bottom: 0;
          border: 0;
          &:not(:last-child) {
            margin-right: 0.6em;
            &::after {
              content: ',';
            }
          }
        }
      }
    }
  }
  .action-container {
    button {
      margin-top: 1rem !important;
    }
  }
  .financial {
    margin: 2rem;
    padding: 0;

    label {
      font-size: 18px;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid var(--color-primary-100);
    }

    .heading {
      color: $color-main;
    }

    table > tbody > tr:last-child {
      td {
        border-bottom: none;
      }
    }

    .value {
      border: 1px solid $color-secondary;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }

    table > tbody > tr:nth-child(even) {
      td {
        background-color: $color-white;
      }
    }

    .remove-debt {
      background-color: transparent;
      font-size: 0.8em !important;
    }

    .error-financialinfo {
      max-width: max-content;
      margin-bottom: 1rem;
    }
  }
}
</style>
